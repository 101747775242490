<template>
  <div class="activityAudit app-container merch-card">
    <el-form
      ref="queryForm"
      :model="queryParams"
      :inline="true"
      style="position: relative"
    >
      <el-form-item>
        <el-input
          v-model="queryParams.supplier"
          placeholder="请输入供应商"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="queryParams.payType"
          clearable
          placeholder="支付方式"
          style="width: 250px"
        >
          <el-option
            v-for="item in payOption"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="minItem">
        <el-date-picker
          v-model="datelist"
          type="datetimerange"
          range-separator="至"
          start-placeholder="请选择开始日期"
          end-placeholder="请选择结束日期"
          unlink-panels
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery" style="width: 80px"
          >查询</el-button
        >
      </el-form-item>
      <div style="position: absolute; right: 5px; bottom: 10px">
        <el-button type="primary" @click="sedrupdaoru">导出</el-button>
      </div>
    </el-form>
    <!-- 列表 -->
    <el-table
      border
      :data="giveData"
      style="width: 100%"
      v-loading="loading"
      :header-cell-style="{ background: '#F2F3F5' }"
      height="600"
      :default-sort="{ prop: 'serialNumber', order: 'descending' }"
      @sort-change="handleSortTable"
    >
      <el-table-column align="center" label="序号" prop="index" width="70"></el-table-column>
      <el-table-column align="center" label="供应商名称" prop="supplier"></el-table-column>
      <el-table-column align="center" label="团队总数" prop="teamCount"></el-table-column>
      <el-table-column align="center" label="业务员人数">
        <template slot-scope="{row}">
          <span class="under-line" @click="openYwy(row.supplierId)">{{row.salesmanCount}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="累计客户">
        <template slot-scope="{row}">
          <span class="under-line" @click="openClient(row.supplierId)">{{row.clientCount}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="总订单数" prop="totalOrderNum" sortable="totalOrderNum"></el-table-column>
      <el-table-column align="center" label="总成交金额" prop="totalAmount" sortable="totalAmount"></el-table-column>
      <el-table-column align="center" label="总佣金" prop="commissionAmount" sortable="commissionAmount"></el-table-column>
      <el-table-column align="center" label="已提现佣金" prop="withdrawnCommissionAmount"></el-table-column>
      <el-table-column align="center" label="不可提现佣金" prop="notWithdrawnCommissionAmount"></el-table-column>
      <el-table-column align="center" label="申请中佣金" prop="applyingCommissionAmount"></el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="merch-page">
      <el-pagination
        :current-page="queryParams.currPage"
        background
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <div>
      <el-dialog title="累计客户" :visible.sync="clientDialog" top="5vh" width="70%">
        <el-form
        ref="clientParams"
        :model="clientParams"
        :inline="true"
        style="position: relative"
      >
        <el-form-item>
          <el-input
            v-model="clientParams.name"
            placeholder="单位名称/简称"
            clearable
            @keyup.enter.native="handleSearchClient"
          />
        </el-form-item>
        <el-form-item>
          <el-cascader
            v-model="clientAreaList"
            :options="AreaList"
            :props="propsValue"
            clearable
            placeholder="省/市/区"
            @change="changeAreaClient"
          ></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClient()" style="width: 80px">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
        <el-table
          border
          :data="clientData"
          style="width: 100%"
          v-loading="loading"
          :header-cell-style="{ background: '#F2F3F5' }"
          height="500"
        >
          <el-table-column align="center" label="客户编号" prop="index" width="140"></el-table-column>
          <el-table-column align="center" label="单位简称" prop="shopName"></el-table-column>
          <el-table-column align="center" label="单位名称" prop="enterprise"></el-table-column>
          <el-table-column align="center" label="客户类型" prop="type"></el-table-column>
          <el-table-column align="center" label="法人姓名" prop="legalEntity"></el-table-column>
          <el-table-column align="center" label="手机号" prop="mobile"></el-table-column>
          <el-table-column align="center" label="企业注册地址" prop="address"></el-table-column>
          <el-table-column align="center" label="注册时间" prop="createTime"></el-table-column>
          <el-table-column align="center" label="最近下单时间" prop="lastBuyTime"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="merch-page">
          <el-pagination
            :current-page="clientParams.currPage"
            background
            @current-change="handleClientChange"
            layout="total, prev, pager, next, jumper"
            :total="clientTotal"
          >
          </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="clientDialog = false" :loading="buttonLoading">确 定</el-button>
            <el-button @click="clientDialog = false">取 消</el-button>
        </span>
      </el-dialog>
    </div>

    <div>
      <el-dialog title="业务员人数" :visible.sync="ywyDialog" width="70%">
        <el-form
        ref="ywyParams"
        :model="ywyParams"
        :inline="true"
        style="position: relative"
      >
        <el-form-item>
          <el-input
            v-model="ywyParams.name"
            placeholder="请输入姓名"
            clearable
            @keyup.enter.native="handleSearchYwy"
          />
        </el-form-item>
        <!-- <el-form-item>
          <el-cascader
            v-model="ywyAreaList"
            :options="AreaList"
            :props="propsValue"
            clearable
            placeholder="省/市/区"
            @change="changeAreaYwy"
          ></el-cascader>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="handleSearchYwy" style="width: 80px"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <!-- 列表 -->
        <el-table
          border
          :data="ywyData"
          style="width: 100%"
          v-loading="loadingYwy"
          :header-cell-style="{ background: '#F2F3F5' }"
          height="600"
        >
          <el-table-column align="center" label="序号" prop="index" width="140"></el-table-column>
          <el-table-column align="center" label="业务员姓名" prop="salesman"></el-table-column>
          <el-table-column align="center" label="属否为负责人" prop="re_status">
            <template slot-scope="{row}">
              <span v-if="row.director == 1">是</span>
              <span v-if="row.director == 0">否</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="性别" prop="re_status">
            <template slot-scope="{row}">
              <span v-if="row.sex == 0">男</span>
              <span v-if="row.sex == 1">女</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="总成交金额" prop="totalAmount"></el-table-column>
          <el-table-column align="center" label="总佣金" prop="commissionAmount"></el-table-column>
          <el-table-column align="center" label="已提现佣金" prop="withdrawnCommissionAmount"></el-table-column>
          <el-table-column align="center" label="未提现佣金" prop="noWithdrawalsCommissionAmount"></el-table-column>
          <el-table-column align="center" label="共提现次数" prop="withdrawnCount"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="merch-page">
          <el-pagination
            :current-page="ywyParams.currPage"
            background
            @current-change="handleYwyChange"
            layout="total, prev, pager, next, jumper"
            :total="ywyTotal"
          >
          </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="ywyDialog = false" :loading="buttonLoading">确 定</el-button>
            <el-button @click="ywyDialog = false">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { downLoadZip } from "@/utils/zipdownload";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("settlement"); //vuex公共库
// const { mapState, mapActions } = createNamespacedHelpers("Order"); //vuex公共库
export default {
  name: "activityAudit",
  data() {
    return {
      ywyDialog:false,
      loadingYwy:false,
      loadingClient:false,
      clientDialog:false,
      payOption: [],
      selData: require("../../assets/json/orderSel.json"),
      // 加载状态
      loading: false,
      buttonLoading:false,
      total:0,
      ywyTotal:0,
      clientTotal:0,
      giveData:[],
      datelist:[],
      clientData:[],
      ywyData:[],
      queryParams: {
        currPage: 1,
        pageSize:10,
        supplier:'',
        payType: null,
        sort:'',
        startTime: "", //开始时间
        endTime: "", //结束时间
      },
      clientParams:{
        currPage:1,
        pageSize:10,
        name:'',
        province:'',
        city:'',
        district:'',
        businessId:'',
      },
      ywyAreaList:[],
      ywyParams:{
        currPage:1,
        pageSize:10,
        name:'',
        province:'',
        city:'',
        district:'',
        businessId:'',
      },
      clientAreaList:[],
      propsValue: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: false,
      }, //级联选择器配置项
    };
  },
  
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.queryParams = this.detailsSeach[router];
      this.datelist=this.detailsSeach[router].datelist
    }
    this.getListApi();
    //前端获取支付方式列表
    this.getPaymentTypeList().then((res) => {
      if (res.code == 200) {
        this.payOption = res.data;
      }
    });
  },
  computed: {
    ...commonIndex.mapState(["AreaList","detailsSeach"]),
  },
  methods: {
    ...mapActions(["getCommissionStatistics", "getCommissionClient","getCommissionSalesman","getPaymentTypeList"]),
    ...commonIndex.mapMutations(["changeSeach"]),
    changeAreaYwy(e){
      this.ywyParams.province = e[0];
      this.ywyParams.city = e[1];
      this.ywyParams.district = e[2];
    },
    openYwy(id){
      this.ywyParams={
        currPage:1,
        pageSize:10,
        name:'',
        province:'',
        city:'',
        district:'',
        businessId:id,
      }
      this.ywyAreaList = []
      this.ywyDialog = true
      this.getYwyList()
    },
    //业务员人数搜索
    handleSearchYwy(){
      this.ywyParams.currPage = 1
      this.getYwyList()
    },
    async getYwyList(){
      this.loadingYwy = true
      let res = await this.getCommissionSalesman(this.ywyParams)
      this.loadingYwy = false
      res.content.list.forEach((v, i) => {
        let index = (res.content.pageNum - 1) * res.content.pageSize + i + 1;
        this.$set(v, "index", index);
      });
      this.ywyData = res.content.list
      this.ywyTotal = res.content.total
    },
    handleYwyChange(e){
      this.ywyParams.currPage = e
      this.getYwyList()
    },
    openClient(id){
      this.clientParams={
        currPage:1,
        pageSize:10,
        name:'',
        province:'',
        city:'',
        district:'',
        businessId:id,
      },
      this.clientAreaList = []
      this.clientDialog = true
      this.getClientList()
    },
    changeAreaClient(e){
      this.clientParams.province = e[0];
      this.clientParams.city = e[1];
      this.clientParams.district = e[2];
    },
    //累计客户搜索
    handleSearchClient(){
      this.clientParams.currPage = 1
      this.getClientList()
    },
    async getClientList(){
      this.loadingClient = true
      let res = await this.getCommissionClient(this.clientParams)
      this.loadingClient = false
      res.content.list.forEach((v, i) => {
        let index = (res.content.pageNum - 1) * res.content.pageSize + i + 1;
        this.$set(v, "index", index);
      });
      this.clientData = res.content.list
      this.clientTotal = res.content.total
    },
    handleClientChange(e){
      this.clientParams.currPage = e
      this.getClientList()
    },
    //分佣统计排序
    handleSortTable(data){
     let sort = data.order === "ascending" ? "asc" : data.order === "descending" ? "desc" : "";
     this.queryParams.sort = `${data.prop} ${sort}`
     this.getListApi();
    },
    async getListApi() {
      this.loading = true;
      if (this.datelist != null) {
        this.queryParams.startTime = this.datelist[0];
        this.queryParams.endTime = this.datelist[1];
      } else {
        this.queryParams.startTime = "";
        this.queryParams.endTime = "";
      }
      let res = await this.getCommissionStatistics(this.queryParams);
      this.loading = false;
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.queryParams };
      save.datelist=this.datelist
      save.router = router;
      this.changeSeach(save);
      res.content.list.forEach((v, i) => {
        let index = (res.content.pageNum - 1) * res.content.pageSize + i + 1;
        this.$set(v, "index", index);
      });
      this.giveData = res.content.list
      this.total = res.content.total
    },
    handleCurrentChange(e) {
      this.queryParams.currPage = e;
      this.getListApi();
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.currPage = 1;
      this.getListApi();
    },
    /** 分佣统计导出 */
    sedrupdaoru() {
      downLoadZip(
        `/mgengine/commission/exportStatistics`,
        "xlsx",
        "分佣统计导出列表",
        this.queryParams,
        "app",
        "post"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.app-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}
.el-form-item {
  margin-bottom: 10px;
}
.sign {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
.opp {
  color: #06b7ae;
  border-radius: 5px;
  font-weight: bold;
  background-color: rgba($color: #2e3238, $alpha: 0.05);
}
.defaultStyle {
  width: 100px;
  padding: 10px 20px;
  text-align: center;
  line-height: 100%;
  font-size: 14px;
  cursor: pointer;
}
.under-line{
  border-bottom: 1px solid #000;
}
.under-line:hover{
  cursor: pointer;
}
::v-deep .el-input--small .el-input__inner {
  height: 40px;
  line-height: 40px;
}
::v-deep .el-form--inline .el-form-item {
  margin-right: 2%;
  margin-top: 1%;
}
</style>
